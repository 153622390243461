import React from 'react'

import SEO from "../components/seo"
import Services from '../containers/Services';
import FaqSection from '../containers/FaqSection';
import Testimonial from '../containers/Testimonial';
import CallToAction from '../containers/CallToAction';
import SectionHeader from '../containers/SectionHeader';
import PartnerSection from '../containers/PartnerSection';

export default function Industry(pageContext) {

  return (
    <>
      <SEO metaData={pageContext.pageContext.seo} lang=""/>
      <SectionHeader {...pageContext} />
      <Services items={pageContext.pageContext.services}/>
      <Testimonial />
      <PartnerSection />
      <FaqSection />
      <CallToAction />
    </>
  )
}
