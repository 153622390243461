import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import parse from 'html-react-parser';
import { openPopupWidget } from "react-calendly";

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Card from '../../common/components/Card';
import Image from '../../common/components/Image';
import Container from '../../common/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './sectionHeader.style';

import ImageBg from '../../common/assets/image/sectionHeaderbackground.png';

const Header = ({ title,
    description,
    textArea,
    imageWrapper,
    btnStyle, 
    pageContext,
    secText,
  }) => {

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };
 
  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject>
        <Card className="objectWrapper sectionBreakPoint" {...imageWrapper}>
          <Zoom>
            <Image src={ImageBg} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              <Image src={pageContext.headerImage.publicURL} alt="VisitorDashboard1" />
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textArea}>

          <Text 
            {...secText} 
            style={{ 'color': `${pageContext.color}` }}
            content={pageContext.title} 
          />

          <Heading 
            content={pageContext.detailTitle}
            {...title}
          />

          <Text
            content={parse(pageContext.longDescription)}
            {...description}
          />

          <Button
            title={pageContext.buttonLabel} 
            {...btnStyle} 
            onClick={handleCalendly}
          />

        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

Header.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
};

Header.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0, '58%'],
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['35px', '28px', '31px', '35px', '43px'],
    fontWeight: 'bolder',
    letterSpacing: '-0.010em',
    mb: '10px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.1',
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    colors: 'main',
    backgroundColor: 'main',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: 'bolder',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    mb: '5px',
    fontWeight: 'bolder',
  },
};

export default Header;
